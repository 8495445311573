import { APIFilter } from '@/utils/api'

export default {
  async selectClienteCuenta (Vue, idclienteCuenta) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcliente_cuenta', idclienteCuenta)
    const resp = await Vue.$api.call('clienteCuenta.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async setClienteCuentaPrincipal (Vue, idclienteCuenta) {
    await Vue.$api.call(
      'clienteCuenta.setClienteCuentaPrincipal',
      {
        idcliente_cuenta: idclienteCuenta,
      },
    )
  },
}
