<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :principal="formattedItem.principal"
          />
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ClienteCuentaViewData'
import ExtraSubtitle from './components/ExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        principal: {
          title: 'Establecer como principal',
          visible: true,
          icon: 'check',
        },
      }
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente_cuenta
        item.title = item.nombre_banco
        item.subtitle = this.$options.filters.hideIBAN(this.item.dataset.iban)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.principal.visible = this.hasEditPerm(this.$route.meta.permission.idobjeto) && !this.item.dataset.principal
      this.title = this.item.dataset.nombre_banco
      this.moreInfoRows = [
        {
          name: 'iban',
          password: true,
          clickable: true,
          icon: this.$vuetify.icons.values.show,
          label: 'IBAN',
        },
        {
          name: 'bic',
          label: 'BIC'
        },
        {
          name: 'ffirmamandato',
          filter: this.$options.filters.shortDate,
          label: 'Fecha de firma de mandato'
        },
        'observacion',
      ]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectClienteCuenta(
          this, this.routeParams.idcliente_cuenta
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      await this.$online.auditoria.auditarViewRecordClienteCuenta(this.routeParams.idcliente_cuenta)
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idcliente_cuenta: this.routeParams.idcliente_cuenta,
          idcliente_potencial: this.routeParams.idcliente_potencial,
          idcliente: this.routeParams.idcliente,
        },
      })
    },
    async clickSummaryRow (row) {
      if (row.icon === this.$vuetify.icons.values.show) {
        this.$loading.showManual('Auditando acceso...')
        try {
          await this.$online.auditoria.auditarViewRecordClienteCuenta(this.routeParams.idcliente_cuenta)
        } finally {
          this.$loading.hide()
        }
        this.setMoreInfoRowValue(row.name, 'password', false)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.hide)
      } else {
        this.setMoreInfoRowValue(row.name, 'password', true)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.show)
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.principal) {
        this.setPrincipal()
      }
    },
    async setPrincipal () {
      const res = await this.$alert.showConfirm(`¿Deseas establecer la cuenta ${this.item.dataset.nombre_banco} como principal`)
      if (res) {
        await Data.setClienteCuentaPrincipal(
          this,
          this.item.dataset.idcliente_cuenta
        )
        this.$alert.showSnackbarSuccess(`Se ha asignado a ${this.item.dataset.nombre_banco} como cuenta principal`)
        this.loadPage()
      }
    },
  },
}
</script>
